import React from 'react';
import { Link } from 'react-router-dom';
import {
  Trash3,
  PencilSquare,
  ShareFill,
  CalendarRange,
  Globe,
} from 'react-bootstrap-icons';
import cs from 'classnames';

import formatDateWithPastWeek from '../../utils/formatDateWithPastWeek';
import FlexContainer from '../../Components/styles/FlexContainer';
import { formatDateRange } from '../../utils/formatDateRange';
import SocialIcon from '../SocialIcon/SocialIcon';
import styles from './CampaignCard.module.scss';

const CampaignCard = ({
  campaign,
  openShareModal,
  openEditModal,
  openDeleteModal,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.networks}>
        <SocialIcon networks={campaign?.platforms || ['']} size={22} />
      </div>

      <div className={styles.settings}>
        <button
          onClick={() => openEditModal(campaign)}
          className={styles.settingsButton}
        >
          <PencilSquare />
        </button>
        <button
          onClick={() => openShareModal(campaign)}
          className={styles.settingsButton}
        >
          <ShareFill />
        </button>
        <button
          onClick={() => openDeleteModal(campaign)}
          className={styles.settingsButton}
        >
          <Trash3 />
        </button>
      </div>
      <Link to={`/campaign/${campaign._id}`} className={styles.details}>
        <h3>{campaign.name}</h3>
        <FlexContainer direction="row" alignItems="center" gap="5px">
          <span
            className={cs(
              `${campaign.active ? styles.active : styles.inactive}`,
            )}
          >
            {campaign.active ? 'Active' : 'Inactive'}
          </span>
          <span>{formatDateWithPastWeek(campaign.created)}</span>
        </FlexContainer>
        {campaign?.notes && (
          <div className={styles.notes}>{campaign?.notes}</div>
        )}

        <div className={styles.spacerContent}>
          {campaign.startDate && campaign.endDate && (
            <FlexContainer direction="row" alignItems="center" gap="5px">
              <CalendarRange />
              <div className={styles.date}>
                {formatDateRange(campaign.startDate, campaign.endDate)}
              </div>
            </FlexContainer>
          )}
          <FlexContainer direction="row" alignItems="flex-start" gap="5px">
            <Globe />
            <div className={styles.domain}>{campaign.domain}</div>
          </FlexContainer>
        </div>
      </Link>

      <div className={styles.detailsBottom}>
        {campaign.startDate && campaign.endDate && (
          <FlexContainer direction="row" alignItems="center" gap="5px">
            <CalendarRange />
            <div className={styles.date}>
              {formatDateRange(campaign.startDate, campaign.endDate)}
            </div>
          </FlexContainer>
        )}
        <FlexContainer
          direction="row"
          alignItems="flex-start"
          gap="5px"
          flexWrap="nowrap"
          className={styles.urlContainer}
        >
          <Globe style={{ marginTop: '4px', flexShrink: 0 }} />
          <div className={styles.domain}>{campaign.domain}</div>
        </FlexContainer>
      </div>
    </div>
  );
};

export default CampaignCard;
