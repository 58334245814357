import React, { useEffect } from 'react';
import cx from 'classnames';
import Input from '../../../../Components/FormComponents/Input';
import styles from '../../styles/Cards.module.scss';
import { useFormCard } from '../../hooks/useFormCard';
import { generateGoogleFinalUrl } from '../../../../utils/urlBuilder';

const GOOGLE_INPUTS = [
  {
    columns: 3,
    inputs: [
      {
        name: 'adDetails.namePrefix',
        type: 'text',
        placeholder: 'Enter name prefix',
        label: 'Name Prefix',
        required: true,
      },
      {
        name: 'adDetails.finalUrl',
        type: 'text',
        placeholder: 'Use URL builder for this',
        label: 'Final URL',
        disabled: true,
      },
    ],
  },
];

const AdDetailsCard = ({ disabled }) => {
  const {
    values,
    handleInputChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
  } = useFormCard();

  useEffect(() => {
    const computedUrl = generateGoogleFinalUrl(values.urlBuilder);

    if (setFieldValue) {
      setFieldValue('adDetails.finalUrl', computedUrl);
    }
  }, [values, setFieldValue]);

  const finalInputs = [
    {
      columns: 3,
      inputs: [
        ...GOOGLE_INPUTS[0].inputs.map((input) =>
          input.name === 'adDetails.finalUrl'
            ? {
                ...input,
                value: undefined,
              }
            : input,
        ),
      ],
    },
  ];

  return (
    <div className={cx(styles.card, { [styles.disabled]: disabled })}>
      <h2 className={styles.title}>Ad Details</h2>
      <div className={styles.formGrid}>
        {finalInputs.map((rowConfig, index) => (
          <div
            key={`row-${index}`}
            className={cx(styles.row, {
              [styles.threeColumns]: rowConfig.columns === 3,
              [styles.twoColumns]: rowConfig.columns === 2,
              [styles.fullWidth]: rowConfig.columns === 1,
            })}
          >
            {rowConfig.inputs.map((input) => (
              <div key={input.name}>
                <Input
                  {...input}
                  value={values?.adDetails?.[input.name.split('.')[1]] || ''}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  error={
                    touched?.adDetails?.[input.name.split('.')[1]] &&
                    errors?.adDetails?.[input.name.split('.')[1]]
                  }
                  disabled={input.disabled ?? disabled}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdDetailsCard;
