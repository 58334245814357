import { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import fetchInstance from '../../../../utils/fetchInstance';
import fetchGoogleInstance from '../../../../utils/fetchGoogleInstance';

export const useGoogleAds = ({ unlockedSteps, setUnlockedSteps }) => {
  const { id } = useParams();
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [bannerSet, setBannerSet] = useState(null);
  const [availableResolutions, setAvailableResolutions] = useState([]);
  const [submitError, setSubmitError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const checkIfUserIsLoggedIn = async () => {
      try {
        const response = await fetchInstance('/checkuser');
        const data = await response.json();
        if (!data.user.googleAccount.name) {
          setLoggedIn(false);
          return;
        }
        setLoggedIn(true);
      } catch (error) {
        console.error('Error checking if user is logged in:', error);
        toast.error('Failed to check if user is logged in Google Ads.');
        setLoggedIn(false);
      }
    };

    checkIfUserIsLoggedIn();

    const fetchBannerSet = async () => {
      try {
        const response = await fetchInstance(`/getBannerSet/${id}`);

        if (!response.ok) {
          throw new Error('Failed to fetch banner set');
        }

        const data = await response.json();

        // TODO: check if bannerSet is for Google or Facebook

        setBannerSet(data);
        if (data.resolutions) {
          const resolutions = data.resolutions.map(
            (res) => `${res.width}x${res.height}`,
          );
          setAvailableResolutions(resolutions);
        }
        if (!unlockedSteps.includes(1)) {
          setUnlockedSteps((prev) => [...prev, 1]);
        }
      } catch (error) {
        console.error('Error fetching banner set:', error);
        toast.error('Failed to fetch banner set.');
        setBannerSet(null);
        setAvailableResolutions([]);
      } finally {
        setInitLoading(false);
      }
    };

    fetchBannerSet();
  }, [id]);

  const validateSubmission = (values) => {
    const isValid =
      values.destination.account &&
      values.destination.campaign &&
      values.destination.adGroup &&
      values.banners.group &&
      values.banners.resolutions?.length > 0 &&
      values.adDetails.namePrefix &&
      values.adDetails.finalUrl;

    return isValid;
  };

  const handleStepComplete = useCallback(
    (step, values) => {
      switch (step) {
        case 'destination':
          if (
            values.destination.account &&
            values.destination.campaign &&
            values.destination.adGroup
          ) {
            setCurrentStep(2);
            if (!unlockedSteps.includes(2)) {
              setUnlockedSteps((prev) => [...prev, 2]);
            }
          }
          break;
        case 'banners':
          if (values.banners.group) {
            setCurrentStep(3);
            if (!unlockedSteps.includes(3)) {
              setUnlockedSteps((prev) => [...prev, 3]);
            }
          }
          break;
        case 'adDetails':
          if (
            values.adDetails.name &&
            values.adDetails.finalUrl &&
            values.adDetails.headline &&
            values.adDetails.primaryText
          ) {
            setCurrentStep(4);
          }
          break;
        default:
          break;
      }
    },
    [unlockedSteps],
  );

  const handleSubmit = useCallback(async (values) => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 10 * 60 * 1000);

    try {
      setLoading(true);
      setSubmitError(null);
      setSuccess(null);
      if (!validateSubmission(values)) {
        throw new Error('Please fill in all required fields');
      }
      await fetchGoogleInstance('/createNewAd', {
        method: 'POST',
        body: JSON.stringify({ ...values, bannerSetId: id }),
        signal: controller.signal,
      });
      setSuccess(true);
    } catch (error) {
      console.error('Error creating Google ad:', error);
      setSubmitError(error.message || error || 'An unexpected error occurred');
      setSuccess(null);
      throw error;
    } finally {
      clearTimeout(timeoutId);
      setLoading(false);
    }
  }, []);

  return {
    currentStep,
    setCurrentStep,
    handleSubmit,
    loading,
    handleStepComplete,
    validateSubmission,
    initLoading,
    bannerSet,
    availableResolutions,
    loggedIn,
    submitError,
    success,
  };
};
